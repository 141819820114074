import * as tslib_1 from "tslib";
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
var ApiService = /** @class */ (function (_super) {
    tslib_1.__extends(ApiService, _super);
    function ApiService(config, http) {
        return _super.call(this, config, http) || this;
    }
    /**
     * login with one userid and password
     * @param logincontent
     * @return Ok
     */
    ApiService.prototype.AuthServiceLoginResponse = function (logincontent) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = logincontent;
        var req = new HttpRequest('POST', this.rootUrl + "/auth/login", __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r.clone({ body: _r.body === 'true' });
        }));
    };
    /**
     * login with one userid and password
     * @param logincontent
     * @return Ok
     */
    ApiService.prototype.AuthServiceLogin = function (logincontent) {
        return this.AuthServiceLoginResponse(logincontent).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all area
     * @param options
     * @return Ok
     */
    ApiService.prototype.AreaServiceGetAreasResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/areas", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all area
     * @param options
     * @return Ok
     */
    ApiService.prototype.AreaServiceGetAreas = function (options) {
        return this.AreaServiceGetAreasResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param area
     * @return Ok
     */
    ApiService.prototype.AreaServiceAddAreaResponse = function (area) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = area;
        var req = new HttpRequest('POST', this.rootUrl + "/areas", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param area
     * @return Ok
     */
    ApiService.prototype.AreaServiceAddArea = function (area) {
        return this.AreaServiceAddAreaResponse(area).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.AreaServiceGetAreaResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/areas/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.AreaServiceGetArea = function (id) {
        return this.AreaServiceGetAreaResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.AreaServiceDeleteAreaResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/areas/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.AreaServiceDeleteArea = function (id) {
        return this.AreaServiceDeleteAreaResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.AreaServiceUpdateAreaParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `area`:
     *
     * @return Ok
     */
    ApiService.prototype.AreaServiceUpdateAreaResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.area;
        var req = new HttpRequest('PUT', this.rootUrl + ("/areas/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.AreaServiceUpdateAreaParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `area`:
     *
     * @return Ok
     */
    ApiService.prototype.AreaServiceUpdateArea = function (params) {
        return this.AreaServiceUpdateAreaResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all box
     * @param options
     * @return Ok
     */
    ApiService.prototype.BoxServiceGetBoxsResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/boxs", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all box
     * @param options
     * @return Ok
     */
    ApiService.prototype.BoxServiceGetBoxs = function (options) {
        return this.BoxServiceGetBoxsResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param box
     * @return Ok
     */
    ApiService.prototype.BoxServiceAddBoxResponse = function (box) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = box;
        var req = new HttpRequest('POST', this.rootUrl + "/boxs", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param box
     * @return Ok
     */
    ApiService.prototype.BoxServiceAddBox = function (box) {
        return this.BoxServiceAddBoxResponse(box).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.BoxServiceGetBoxResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/boxs/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.BoxServiceGetBox = function (id) {
        return this.BoxServiceGetBoxResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.BoxServiceDeleteBoxResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/boxs/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.BoxServiceDeleteBox = function (id) {
        return this.BoxServiceDeleteBoxResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.BoxServiceUpdateBoxParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `box`:
     *
     * @return Ok
     */
    ApiService.prototype.BoxServiceUpdateBoxResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.box;
        var req = new HttpRequest('PUT', this.rootUrl + ("/boxs/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.BoxServiceUpdateBoxParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `box`:
     *
     * @return Ok
     */
    ApiService.prototype.BoxServiceUpdateBox = function (params) {
        return this.BoxServiceUpdateBoxResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all boxelement
     * @param options
     * @return Ok
     */
    ApiService.prototype.BoxElementServiceGetBoxElementsResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/boxelements", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all boxelement
     * @param options
     * @return Ok
     */
    ApiService.prototype.BoxElementServiceGetBoxElements = function (options) {
        return this.BoxElementServiceGetBoxElementsResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param boxelement
     * @return Ok
     */
    ApiService.prototype.BoxElementServiceAddBoxElementResponse = function (boxelement) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = boxelement;
        var req = new HttpRequest('POST', this.rootUrl + "/boxelements", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param boxelement
     * @return Ok
     */
    ApiService.prototype.BoxElementServiceAddBoxElement = function (boxelement) {
        return this.BoxElementServiceAddBoxElementResponse(boxelement).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.BoxElementServiceGetBoxElementResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/boxelements/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.BoxElementServiceGetBoxElement = function (id) {
        return this.BoxElementServiceGetBoxElementResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.BoxElementServiceDeleteBoxElementResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/boxelements/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.BoxElementServiceDeleteBoxElement = function (id) {
        return this.BoxElementServiceDeleteBoxElementResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.BoxElementServiceUpdateBoxElementParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `boxelement`:
     *
     * @return Ok
     */
    ApiService.prototype.BoxElementServiceUpdateBoxElementResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.boxelement;
        var req = new HttpRequest('PUT', this.rootUrl + ("/boxelements/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.BoxElementServiceUpdateBoxElementParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `boxelement`:
     *
     * @return Ok
     */
    ApiService.prototype.BoxElementServiceUpdateBoxElement = function (params) {
        return this.BoxElementServiceUpdateBoxElementResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all foundposition
     * @param options
     * @return Ok
     */
    ApiService.prototype.FoundPositionServiceGetFoundPositionsResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/foundpositions", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all foundposition
     * @param options
     * @return Ok
     */
    ApiService.prototype.FoundPositionServiceGetFoundPositions = function (options) {
        return this.FoundPositionServiceGetFoundPositionsResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param foundposition
     * @return Ok
     */
    ApiService.prototype.FoundPositionServiceAddFoundPositionResponse = function (foundposition) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = foundposition;
        var req = new HttpRequest('POST', this.rootUrl + "/foundpositions", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param foundposition
     * @return Ok
     */
    ApiService.prototype.FoundPositionServiceAddFoundPosition = function (foundposition) {
        return this.FoundPositionServiceAddFoundPositionResponse(foundposition).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.FoundPositionServiceGetFoundPositionResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/foundpositions/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.FoundPositionServiceGetFoundPosition = function (id) {
        return this.FoundPositionServiceGetFoundPositionResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.FoundPositionServiceDeleteFoundPositionResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/foundpositions/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.FoundPositionServiceDeleteFoundPosition = function (id) {
        return this.FoundPositionServiceDeleteFoundPositionResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.FoundPositionServiceUpdateFoundPositionParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `foundposition`:
     *
     * @return Ok
     */
    ApiService.prototype.FoundPositionServiceUpdateFoundPositionResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.foundposition;
        var req = new HttpRequest('PUT', this.rootUrl + ("/foundpositions/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.FoundPositionServiceUpdateFoundPositionParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `foundposition`:
     *
     * @return Ok
     */
    ApiService.prototype.FoundPositionServiceUpdateFoundPosition = function (params) {
        return this.FoundPositionServiceUpdateFoundPositionResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all guicontext
     * @param options
     * @return Ok
     */
    ApiService.prototype.GUIContextServiceGetGUIContextsResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/guicontexts", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all guicontext
     * @param options
     * @return Ok
     */
    ApiService.prototype.GUIContextServiceGetGUIContexts = function (options) {
        return this.GUIContextServiceGetGUIContextsResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param guicontext
     * @return Ok
     */
    ApiService.prototype.GUIContextServiceAddGUIContextResponse = function (guicontext) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = guicontext;
        var req = new HttpRequest('POST', this.rootUrl + "/guicontexts", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param guicontext
     * @return Ok
     */
    ApiService.prototype.GUIContextServiceAddGUIContext = function (guicontext) {
        return this.GUIContextServiceAddGUIContextResponse(guicontext).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.GUIContextServiceGetGUIContextResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/guicontexts/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.GUIContextServiceGetGUIContext = function (id) {
        return this.GUIContextServiceGetGUIContextResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.GUIContextServiceDeleteGUIContextResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/guicontexts/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.GUIContextServiceDeleteGUIContext = function (id) {
        return this.GUIContextServiceDeleteGUIContextResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.GUIContextServiceUpdateGUIContextParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `guicontext`:
     *
     * @return Ok
     */
    ApiService.prototype.GUIContextServiceUpdateGUIContextResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.guicontext;
        var req = new HttpRequest('PUT', this.rootUrl + ("/guicontexts/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.GUIContextServiceUpdateGUIContextParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `guicontext`:
     *
     * @return Ok
     */
    ApiService.prototype.GUIContextServiceUpdateGUIContext = function (params) {
        return this.GUIContextServiceUpdateGUIContextResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all markerconfiguration
     * @param options
     * @return Ok
     */
    ApiService.prototype.MarkerConfigurationServiceGetMarkerConfigurationsResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/markerconfigurations", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all markerconfiguration
     * @param options
     * @return Ok
     */
    ApiService.prototype.MarkerConfigurationServiceGetMarkerConfigurations = function (options) {
        return this.MarkerConfigurationServiceGetMarkerConfigurationsResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param markerconfiguration
     * @return Ok
     */
    ApiService.prototype.MarkerConfigurationServiceAddMarkerConfigurationResponse = function (markerconfiguration) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = markerconfiguration;
        var req = new HttpRequest('POST', this.rootUrl + "/markerconfigurations", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param markerconfiguration
     * @return Ok
     */
    ApiService.prototype.MarkerConfigurationServiceAddMarkerConfiguration = function (markerconfiguration) {
        return this.MarkerConfigurationServiceAddMarkerConfigurationResponse(markerconfiguration).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.MarkerConfigurationServiceGetMarkerConfigurationResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/markerconfigurations/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.MarkerConfigurationServiceGetMarkerConfiguration = function (id) {
        return this.MarkerConfigurationServiceGetMarkerConfigurationResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.MarkerConfigurationServiceDeleteMarkerConfigurationResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/markerconfigurations/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.MarkerConfigurationServiceDeleteMarkerConfiguration = function (id) {
        return this.MarkerConfigurationServiceDeleteMarkerConfigurationResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.MarkerConfigurationServiceUpdateMarkerConfigurationParams` containing the following parameters:
     *
     * - `markerconfiguration`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.MarkerConfigurationServiceUpdateMarkerConfigurationResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.markerconfiguration;
        var req = new HttpRequest('PUT', this.rootUrl + ("/markerconfigurations/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.MarkerConfigurationServiceUpdateMarkerConfigurationParams` containing the following parameters:
     *
     * - `markerconfiguration`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.MarkerConfigurationServiceUpdateMarkerConfiguration = function (params) {
        return this.MarkerConfigurationServiceUpdateMarkerConfigurationResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all place
     * @param options
     * @return Ok
     */
    ApiService.prototype.PlaceServiceGetPlacesResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/places", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all place
     * @param options
     * @return Ok
     */
    ApiService.prototype.PlaceServiceGetPlaces = function (options) {
        return this.PlaceServiceGetPlacesResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param place
     * @return Ok
     */
    ApiService.prototype.PlaceServiceAddPlaceResponse = function (place) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = place;
        var req = new HttpRequest('POST', this.rootUrl + "/places", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param place
     * @return Ok
     */
    ApiService.prototype.PlaceServiceAddPlace = function (place) {
        return this.PlaceServiceAddPlaceResponse(place).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.PlaceServiceGetPlaceResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/places/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.PlaceServiceGetPlace = function (id) {
        return this.PlaceServiceGetPlaceResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.PlaceServiceDeletePlaceResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/places/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.PlaceServiceDeletePlace = function (id) {
        return this.PlaceServiceDeletePlaceResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.PlaceServiceUpdatePlaceParams` containing the following parameters:
     *
     * - `place`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.PlaceServiceUpdatePlaceResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.place;
        var req = new HttpRequest('PUT', this.rootUrl + ("/places/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.PlaceServiceUpdatePlaceParams` containing the following parameters:
     *
     * - `place`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.PlaceServiceUpdatePlace = function (params) {
        return this.PlaceServiceUpdatePlaceResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all plannedlayout
     * @param options
     * @return Ok
     */
    ApiService.prototype.PlannedLayoutServiceGetPlannedLayoutsResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/plannedlayouts", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all plannedlayout
     * @param options
     * @return Ok
     */
    ApiService.prototype.PlannedLayoutServiceGetPlannedLayouts = function (options) {
        return this.PlannedLayoutServiceGetPlannedLayoutsResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param plannedlayout
     * @return Ok
     */
    ApiService.prototype.PlannedLayoutServiceAddPlannedLayoutResponse = function (plannedlayout) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = plannedlayout;
        var req = new HttpRequest('POST', this.rootUrl + "/plannedlayouts", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param plannedlayout
     * @return Ok
     */
    ApiService.prototype.PlannedLayoutServiceAddPlannedLayout = function (plannedlayout) {
        return this.PlannedLayoutServiceAddPlannedLayoutResponse(plannedlayout).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.PlannedLayoutServiceGetPlannedLayoutResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/plannedlayouts/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.PlannedLayoutServiceGetPlannedLayout = function (id) {
        return this.PlannedLayoutServiceGetPlannedLayoutResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.PlannedLayoutServiceDeletePlannedLayoutResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/plannedlayouts/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.PlannedLayoutServiceDeletePlannedLayout = function (id) {
        return this.PlannedLayoutServiceDeletePlannedLayoutResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.PlannedLayoutServiceUpdatePlannedLayoutParams` containing the following parameters:
     *
     * - `plannedlayout`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.PlannedLayoutServiceUpdatePlannedLayoutResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.plannedlayout;
        var req = new HttpRequest('PUT', this.rootUrl + ("/plannedlayouts/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.PlannedLayoutServiceUpdatePlannedLayoutParams` containing the following parameters:
     *
     * - `plannedlayout`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.PlannedLayoutServiceUpdatePlannedLayout = function (params) {
        return this.PlannedLayoutServiceUpdatePlannedLayoutResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all referencetransform
     * @param options
     * @return Ok
     */
    ApiService.prototype.ReferenceTransformServiceGetReferenceTransformsResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/referencetransforms", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all referencetransform
     * @param options
     * @return Ok
     */
    ApiService.prototype.ReferenceTransformServiceGetReferenceTransforms = function (options) {
        return this.ReferenceTransformServiceGetReferenceTransformsResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param referencetransform
     * @return Ok
     */
    ApiService.prototype.ReferenceTransformServiceAddReferenceTransformResponse = function (referencetransform) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = referencetransform;
        var req = new HttpRequest('POST', this.rootUrl + "/referencetransforms", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param referencetransform
     * @return Ok
     */
    ApiService.prototype.ReferenceTransformServiceAddReferenceTransform = function (referencetransform) {
        return this.ReferenceTransformServiceAddReferenceTransformResponse(referencetransform).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.ReferenceTransformServiceGetReferenceTransformResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/referencetransforms/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.ReferenceTransformServiceGetReferenceTransform = function (id) {
        return this.ReferenceTransformServiceGetReferenceTransformResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.ReferenceTransformServiceDeleteReferenceTransformResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/referencetransforms/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.ReferenceTransformServiceDeleteReferenceTransform = function (id) {
        return this.ReferenceTransformServiceDeleteReferenceTransformResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.ReferenceTransformServiceUpdateReferenceTransformParams` containing the following parameters:
     *
     * - `referencetransform`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.ReferenceTransformServiceUpdateReferenceTransformResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.referencetransform;
        var req = new HttpRequest('PUT', this.rootUrl + ("/referencetransforms/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.ReferenceTransformServiceUpdateReferenceTransformParams` containing the following parameters:
     *
     * - `referencetransform`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.ReferenceTransformServiceUpdateReferenceTransform = function (params) {
        return this.ReferenceTransformServiceUpdateReferenceTransformResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all role
     * @param options
     * @return Ok
     */
    ApiService.prototype.RoleServiceGetRolesResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/roles", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all role
     * @param options
     * @return Ok
     */
    ApiService.prototype.RoleServiceGetRoles = function (options) {
        return this.RoleServiceGetRolesResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param role
     * @return Ok
     */
    ApiService.prototype.RoleServiceAddRoleResponse = function (role) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = role;
        var req = new HttpRequest('POST', this.rootUrl + "/roles", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param role
     * @return Ok
     */
    ApiService.prototype.RoleServiceAddRole = function (role) {
        return this.RoleServiceAddRoleResponse(role).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.RoleServiceGetRoleResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/roles/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.RoleServiceGetRole = function (id) {
        return this.RoleServiceGetRoleResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.RoleServiceDeleteRoleResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/roles/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.RoleServiceDeleteRole = function (id) {
        return this.RoleServiceDeleteRoleResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.RoleServiceUpdateRoleParams` containing the following parameters:
     *
     * - `role`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.RoleServiceUpdateRoleResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.role;
        var req = new HttpRequest('PUT', this.rootUrl + ("/roles/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.RoleServiceUpdateRoleParams` containing the following parameters:
     *
     * - `role`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.RoleServiceUpdateRole = function (params) {
        return this.RoleServiceUpdateRoleResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all site
     * @param options
     * @return Ok
     */
    ApiService.prototype.SiteServiceGetSitesResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/sites", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all site
     * @param options
     * @return Ok
     */
    ApiService.prototype.SiteServiceGetSites = function (options) {
        return this.SiteServiceGetSitesResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param site
     * @return Ok
     */
    ApiService.prototype.SiteServiceAddSiteResponse = function (site) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = site;
        var req = new HttpRequest('POST', this.rootUrl + "/sites", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param site
     * @return Ok
     */
    ApiService.prototype.SiteServiceAddSite = function (site) {
        return this.SiteServiceAddSiteResponse(site).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.SiteServiceGetSiteResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/sites/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.SiteServiceGetSite = function (id) {
        return this.SiteServiceGetSiteResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.SiteServiceDeleteSiteResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/sites/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.SiteServiceDeleteSite = function (id) {
        return this.SiteServiceDeleteSiteResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.SiteServiceUpdateSiteParams` containing the following parameters:
     *
     * - `site`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.SiteServiceUpdateSiteResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.site;
        var req = new HttpRequest('PUT', this.rootUrl + ("/sites/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.SiteServiceUpdateSiteParams` containing the following parameters:
     *
     * - `site`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.SiteServiceUpdateSite = function (params) {
        return this.SiteServiceUpdateSiteResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all stocktake
     * @param options
     * @return Ok
     */
    ApiService.prototype.StocktakeServiceGetStocktakesResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/stocktakes", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all stocktake
     * @param options
     * @return Ok
     */
    ApiService.prototype.StocktakeServiceGetStocktakes = function (options) {
        return this.StocktakeServiceGetStocktakesResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param stocktake
     * @return Ok
     */
    ApiService.prototype.StocktakeServiceAddStocktakeResponse = function (stocktake) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = stocktake;
        var req = new HttpRequest('POST', this.rootUrl + "/stocktakes", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    ApiService.prototype.StocktakeColaApiResponse = function (stocktake, selectedDate) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = { stocktake: stocktake, selectedDate: selectedDate };
        var req = new HttpRequest('POST', this.rootUrl + "/stocktakes/colaApi", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param stocktake
     * @return Ok
     */
    ApiService.prototype.StocktakeServiceAddStocktake = function (stocktake) {
        return this.StocktakeServiceAddStocktakeResponse(stocktake).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.prototype.StocktakeColaApi = function (data, selectedDate) {
        return this.StocktakeColaApiResponse(data, selectedDate).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.StocktakeServiceGetStocktakeResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/stocktakes/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.StocktakeServiceGetStocktake = function (id) {
        return this.StocktakeServiceGetStocktakeResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.StocktakeServiceDeleteStocktakeResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/stocktakes/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.StocktakeServiceDeleteStocktake = function (id) {
        return this.StocktakeServiceDeleteStocktakeResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.StocktakeServiceUpdateStocktakeParams` containing the following parameters:
     *
     * - `stocktake`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.StocktakeServiceUpdateStocktakeResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.stocktake;
        var req = new HttpRequest('PUT', this.rootUrl + ("/stocktakes/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.StocktakeServiceUpdateStocktakeParams` containing the following parameters:
     *
     * - `stocktake`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.StocktakeServiceUpdateStocktake = function (params) {
        return this.StocktakeServiceUpdateStocktakeResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all stocktaketoarea
     * @param options
     * @return Ok
     */
    ApiService.prototype.StocktakeToAreaServiceGetStocktakeToAreasResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/stocktaketoareas", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all stocktaketoarea
     * @param options
     * @return Ok
     */
    ApiService.prototype.StocktakeToAreaServiceGetStocktakeToAreas = function (options) {
        return this.StocktakeToAreaServiceGetStocktakeToAreasResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param stocktaketoarea
     * @return Ok
     */
    ApiService.prototype.StocktakeToAreaServiceAddStocktakeToAreaResponse = function (stocktaketoarea) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = stocktaketoarea;
        var req = new HttpRequest('POST', this.rootUrl + "/stocktaketoareas", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param stocktaketoarea
     * @return Ok
     */
    ApiService.prototype.StocktakeToAreaServiceAddStocktakeToArea = function (stocktaketoarea) {
        return this.StocktakeToAreaServiceAddStocktakeToAreaResponse(stocktaketoarea).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.StocktakeToAreaServiceGetStocktakeToAreaResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/stocktaketoareas/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.StocktakeToAreaServiceGetStocktakeToArea = function (id) {
        return this.StocktakeToAreaServiceGetStocktakeToAreaResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.StocktakeToAreaServiceDeleteStocktakeToAreaResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/stocktaketoareas/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.StocktakeToAreaServiceDeleteStocktakeToArea = function (id) {
        return this.StocktakeToAreaServiceDeleteStocktakeToAreaResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.StocktakeToAreaServiceUpdateStocktakeToAreaParams` containing the following parameters:
     *
     * - `stocktaketoarea`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.StocktakeToAreaServiceUpdateStocktakeToAreaResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.stocktaketoarea;
        var req = new HttpRequest('PUT', this.rootUrl + ("/stocktaketoareas/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.StocktakeToAreaServiceUpdateStocktakeToAreaParams` containing the following parameters:
     *
     * - `stocktaketoarea`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.StocktakeToAreaServiceUpdateStocktakeToArea = function (params) {
        return this.StocktakeToAreaServiceUpdateStocktakeToAreaResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all user
     * @param options
     * @return Ok
     */
    ApiService.prototype.UserServiceGetUsersResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/users", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all user
     * @param options
     * @return Ok
     */
    ApiService.prototype.UserServiceGetUsers = function (options) {
        return this.UserServiceGetUsersResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param user
     * @return Ok
     */
    ApiService.prototype.UserServiceAddUserResponse = function (user) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = user;
        var req = new HttpRequest('POST', this.rootUrl + "/users", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param user
     * @return Ok
     */
    ApiService.prototype.UserServiceAddUser = function (user) {
        return this.UserServiceAddUserResponse(user).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.UserServiceGetUserResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/users/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.UserServiceGetUser = function (id) {
        return this.UserServiceGetUserResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.UserServiceDeleteUserResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/users/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.UserServiceDeleteUser = function (id) {
        return this.UserServiceDeleteUserResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.UserServiceUpdateUserParams` containing the following parameters:
     *
     * - `user`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.UserServiceUpdateUserResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.user;
        var req = new HttpRequest('PUT', this.rootUrl + ("/users/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.UserServiceUpdateUserParams` containing the following parameters:
     *
     * - `user`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.UserServiceUpdateUser = function (params) {
        return this.UserServiceUpdateUserResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * get all userauthdata
     * @param options
     * @return Ok
     */
    ApiService.prototype.UserAuthDataServiceGetUserAuthDatasResponse = function (options) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        if (options != null)
            __params = __params.set('options', options.toString());
        var req = new HttpRequest('GET', this.rootUrl + "/userauthdatas", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * get all userauthdata
     * @param options
     * @return Ok
     */
    ApiService.prototype.UserAuthDataServiceGetUserAuthDatas = function (options) {
        return this.UserAuthDataServiceGetUserAuthDatasResponse(options).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param userauthdata
     * @return Ok
     */
    ApiService.prototype.UserAuthDataServiceAddUserAuthDataResponse = function (userauthdata) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = userauthdata;
        var req = new HttpRequest('POST', this.rootUrl + "/userauthdatas", __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param userauthdata
     * @return Ok
     */
    ApiService.prototype.UserAuthDataServiceAddUserAuthData = function (userauthdata) {
        return this.UserAuthDataServiceAddUserAuthDataResponse(userauthdata).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.UserAuthDataServiceGetUserAuthDataResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('GET', this.rootUrl + ("/userauthdatas/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     * @return Ok
     */
    ApiService.prototype.UserAuthDataServiceGetUserAuthData = function (id) {
        return this.UserAuthDataServiceGetUserAuthDataResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param id
     */
    ApiService.prototype.UserAuthDataServiceDeleteUserAuthDataResponse = function (id) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        var req = new HttpRequest('DELETE', this.rootUrl + ("/userauthdatas/" + id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param id
     */
    ApiService.prototype.UserAuthDataServiceDeleteUserAuthData = function (id) {
        return this.UserAuthDataServiceDeleteUserAuthDataResponse(id).pipe(__map(function (_r) { return _r.body; }));
    };
    /**
     * @param params The `ApiService.UserAuthDataServiceUpdateUserAuthDataParams` containing the following parameters:
     *
     * - `userauthdata`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.UserAuthDataServiceUpdateUserAuthDataResponse = function (params) {
        var __params = this.newParams();
        var __headers = new HttpHeaders();
        var __body = null;
        __body = params.userauthdata;
        var req = new HttpRequest('PUT', this.rootUrl + ("/userauthdatas/" + params.id), __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(function (_r) { return _r instanceof HttpResponse; }), __map(function (_r) {
            return _r;
        }));
    };
    /**
     * @param params The `ApiService.UserAuthDataServiceUpdateUserAuthDataParams` containing the following parameters:
     *
     * - `userauthdata`:
     *
     * - `id`:
     *
     * @return Ok
     */
    ApiService.prototype.UserAuthDataServiceUpdateUserAuthData = function (params) {
        return this.UserAuthDataServiceUpdateUserAuthDataResponse(params).pipe(__map(function (_r) { return _r.body; }));
    };
    ApiService.AuthServiceLoginPath = '/auth/login';
    ApiService.AreaServiceGetAreasPath = '/areas';
    ApiService.AreaServiceAddAreaPath = '/areas';
    ApiService.AreaServiceGetAreaPath = '/areas/{id}';
    ApiService.AreaServiceDeleteAreaPath = '/areas/{id}';
    ApiService.AreaServiceUpdateAreaPath = '/areas/{id}';
    ApiService.BoxServiceGetBoxsPath = '/boxs';
    ApiService.BoxServiceAddBoxPath = '/boxs';
    ApiService.BoxServiceGetBoxPath = '/boxs/{id}';
    ApiService.BoxServiceDeleteBoxPath = '/boxs/{id}';
    ApiService.BoxServiceUpdateBoxPath = '/boxs/{id}';
    ApiService.BoxElementServiceGetBoxElementsPath = '/boxelements';
    ApiService.BoxElementServiceAddBoxElementPath = '/boxelements';
    ApiService.BoxElementServiceGetBoxElementPath = '/boxelements/{id}';
    ApiService.BoxElementServiceDeleteBoxElementPath = '/boxelements/{id}';
    ApiService.BoxElementServiceUpdateBoxElementPath = '/boxelements/{id}';
    ApiService.FoundPositionServiceGetFoundPositionsPath = '/foundpositions';
    ApiService.FoundPositionServiceAddFoundPositionPath = '/foundpositions';
    ApiService.FoundPositionServiceGetFoundPositionPath = '/foundpositions/{id}';
    ApiService.FoundPositionServiceDeleteFoundPositionPath = '/foundpositions/{id}';
    ApiService.FoundPositionServiceUpdateFoundPositionPath = '/foundpositions/{id}';
    ApiService.GUIContextServiceGetGUIContextsPath = '/guicontexts';
    ApiService.GUIContextServiceAddGUIContextPath = '/guicontexts';
    ApiService.GUIContextServiceGetGUIContextPath = '/guicontexts/{id}';
    ApiService.GUIContextServiceDeleteGUIContextPath = '/guicontexts/{id}';
    ApiService.GUIContextServiceUpdateGUIContextPath = '/guicontexts/{id}';
    ApiService.MarkerConfigurationServiceGetMarkerConfigurationsPath = '/markerconfigurations';
    ApiService.MarkerConfigurationServiceAddMarkerConfigurationPath = '/markerconfigurations';
    ApiService.MarkerConfigurationServiceGetMarkerConfigurationPath = '/markerconfigurations/{id}';
    ApiService.MarkerConfigurationServiceDeleteMarkerConfigurationPath = '/markerconfigurations/{id}';
    ApiService.MarkerConfigurationServiceUpdateMarkerConfigurationPath = '/markerconfigurations/{id}';
    ApiService.PlaceServiceGetPlacesPath = '/places';
    ApiService.PlaceServiceAddPlacePath = '/places';
    ApiService.PlaceServiceGetPlacePath = '/places/{id}';
    ApiService.PlaceServiceDeletePlacePath = '/places/{id}';
    ApiService.PlaceServiceUpdatePlacePath = '/places/{id}';
    ApiService.PlannedLayoutServiceGetPlannedLayoutsPath = '/plannedlayouts';
    ApiService.PlannedLayoutServiceAddPlannedLayoutPath = '/plannedlayouts';
    ApiService.PlannedLayoutServiceGetPlannedLayoutPath = '/plannedlayouts/{id}';
    ApiService.PlannedLayoutServiceDeletePlannedLayoutPath = '/plannedlayouts/{id}';
    ApiService.PlannedLayoutServiceUpdatePlannedLayoutPath = '/plannedlayouts/{id}';
    ApiService.ReferenceTransformServiceGetReferenceTransformsPath = '/referencetransforms';
    ApiService.ReferenceTransformServiceAddReferenceTransformPath = '/referencetransforms';
    ApiService.ReferenceTransformServiceGetReferenceTransformPath = '/referencetransforms/{id}';
    ApiService.ReferenceTransformServiceDeleteReferenceTransformPath = '/referencetransforms/{id}';
    ApiService.ReferenceTransformServiceUpdateReferenceTransformPath = '/referencetransforms/{id}';
    ApiService.RoleServiceGetRolesPath = '/roles';
    ApiService.RoleServiceAddRolePath = '/roles';
    ApiService.RoleServiceGetRolePath = '/roles/{id}';
    ApiService.RoleServiceDeleteRolePath = '/roles/{id}';
    ApiService.RoleServiceUpdateRolePath = '/roles/{id}';
    ApiService.SiteServiceGetSitesPath = '/sites';
    ApiService.SiteServiceAddSitePath = '/sites';
    ApiService.SiteServiceGetSitePath = '/sites/{id}';
    ApiService.SiteServiceDeleteSitePath = '/sites/{id}';
    ApiService.SiteServiceUpdateSitePath = '/sites/{id}';
    ApiService.StocktakeServiceGetStocktakesPath = '/stocktakes';
    ApiService.StocktakeServiceAddStocktakePath = '/stocktakes';
    ApiService.StocktakeServiceGetStocktakePath = '/stocktakes/{id}';
    ApiService.StocktakeServiceDeleteStocktakePath = '/stocktakes/{id}';
    ApiService.StocktakeServiceUpdateStocktakePath = '/stocktakes/{id}';
    ApiService.StocktakeToAreaServiceGetStocktakeToAreasPath = '/stocktaketoareas';
    ApiService.StocktakeToAreaServiceAddStocktakeToAreaPath = '/stocktaketoareas';
    ApiService.StocktakeToAreaServiceGetStocktakeToAreaPath = '/stocktaketoareas/{id}';
    ApiService.StocktakeToAreaServiceDeleteStocktakeToAreaPath = '/stocktaketoareas/{id}';
    ApiService.StocktakeToAreaServiceUpdateStocktakeToAreaPath = '/stocktaketoareas/{id}';
    ApiService.UserServiceGetUsersPath = '/users';
    ApiService.UserServiceAddUserPath = '/users';
    ApiService.UserServiceGetUserPath = '/users/{id}';
    ApiService.UserServiceDeleteUserPath = '/users/{id}';
    ApiService.UserServiceUpdateUserPath = '/users/{id}';
    ApiService.UserAuthDataServiceGetUserAuthDatasPath = '/userauthdatas';
    ApiService.UserAuthDataServiceAddUserAuthDataPath = '/userauthdatas';
    ApiService.UserAuthDataServiceGetUserAuthDataPath = '/userauthdatas/{id}';
    ApiService.UserAuthDataServiceDeleteUserAuthDataPath = '/userauthdatas/{id}';
    ApiService.UserAuthDataServiceUpdateUserAuthDataPath = '/userauthdatas/{id}';
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.ApiConfiguration), i0.ɵɵinject(i2.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}(__BaseService));
export { ApiService };
