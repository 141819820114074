import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'yesno-modal',
  templateUrl: './yesnomodal.component.html',
  styleUrls: ['./yesnomodal.component.scss']
})
export class yesNoModal {

  modalHeader: string;
  modalContent: string;
  modalDate?: boolean;
  selectedDate?: Date

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() { }

  closeModal(result: boolean) {
    if (result && this.modalDate && this.selectedDate) {
      const formattedDate = this.transformDate();
      this.activeModal.close(formattedDate);
    } else {
      this.activeModal.close(result);
    }

  }

  transformDate(): string {
    const selectedDate = new Date(this.selectedDate);

    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const day = String(selectedDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}T00:00:00`;
  }

}
