<div class="modal-content">

  <div class="modal-header">
    <h4 class="modal-title">{{modalHeader}}</h4>
    <button class="close" aria-label="Close" (click)="closeModal(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    {{modalContent}}
  </div>

  <div class="date-block">
    <mat-form-field *ngIf="modalDate">
      <input matInput
             [matDatepicker]="picker"
             [placeholder]="'Wählen Sie bei Bedarf das Inventurdatum aus'"
             [(ngModel)]="selectedDate"
             (focus)="picker.open()"
      >
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>


  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button class="btn btn-danger confirm-btn left" (click)="closeModal(false)">Nein</button>
      </div>
      <div class="col-6">
        <button class="btn btn-primary confirm-btn right" (click)="closeModal(true)">Ja</button>
      </div>
    </div>
  </div>

</div>
